<template>
  <div ref="promotionContainer" class="promotion-container">
    <p class="promotion-title">Our Promotions</p>
    <div class="card-container">
      <button :disabled="startIndex === 0" v-if="shouldShowLeftArrow" class="promotion-prev-button" @click="showPrevPromotionCards" ref="prevButton"></button>
      <img ref="promotionCard" v-for="(card, index) in visibleArray" :key="index" :src="card.data.imageUrl" alt="p1" class="promotion-card" @click="showModal(card.data.imageUrl)">
      <button :disabled="shouldDisableRightArrow" v-if="shouldShowRightArrow" class="promotion-next-button" @click="showNextPromotionCards" ref="nextButton"></button>
    </div>
  </div>
  <ImageModal
    v-if="this.selectedPromotion"
    :image-url="selectedPromotion"
    @closeModal="closeModal"
  />
</template>

<script>

import ImageModal from "@/components/modal/image_modal/ImageModal.vue";

function promotionLists(imageUrl) {
  return {
    data : {
      imageUrl: imageUrl,
    }
  }
}export default {
  name: 'PromotionPage',
  props: {
  },
  components: {
    ImageModal,
  },
  data() {
    return {
      showNavigator: false,
      selectedPromotion: null,
      promotions: [
        promotionLists('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/zxc.png'),
        promotionLists('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/3.png'),
        promotionLists('https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/1.png'),

      ],
      visibleArray: [],
      startIndex: 0,
      intervalId: null,
      shouldShowRightArrow: false,
      shouldShowLeftArrow: false,
      cardUpdated: false,
      shouldDisableRightArrow: false,
      disableClick: false
    };
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.cardUpdated = true
      this.shouldShowLeftArrow = true
      this.shouldShowRightArrow = true
      this.visibleArray = this.promotions.slice(this.startIndex, this.startIndex + 1)
      const timeout = sessionStorage.getItem('isFirstMounted') ? 0 : 3000
      this.$nextTick(() => {
        const firstCard = this.$refs.promotionCard[0];
        firstCard.setAttribute('data-active', '');
        setTimeout(() => {
          this.intervalId = setInterval(() => {
            const newCard = this.$refs.promotionCard[0];
            if (newCard) {
              newCard.removeAttribute('data-active');
            }
            setTimeout(() => {
              this.startIndex = (this.startIndex + 1) % this.promotions.length
              this.visibleArray = this.promotions.slice(this.startIndex, this.startIndex + 1)
              this.shouldDisableRightArrow = this.visibleArray.at(this.visibleArray.length - 1).data.imageUrl === this.promotions.at(this.promotions.length - 1).data.imageUrl
              newCard.setAttribute('data-active', '');
            }, 400);
          }, 5000)
        }, timeout)
      });
    } else {
      this.visibleArray = this.promotions.slice(this.startIndex, this.promotions.length)
    }
    window.addEventListener('resize', this.updateVisibleArray);
  },
  beforeUnmount() {
    clearInterval(this.intervalId)
    window.removeEventListener('resize', this.updateVisibleArray);
  },
  watch: {
    visibleArray(newValue) {
      if (window.innerHeight < 768 && newValue.at(newValue.length - 1) === this.promotions.at(this.promotions.length - 1)) {
        // this.shouldDisableRightArrow = true
      } else if (window.innerHeight < 768 && newValue.at(newValue.length - 1) !== this.promotions.at(this.promotions.length - 1)) {
        // this.shouldDisableRightArrow = false
      }
    }
  },
  methods: {
    closeModal() {
      this.selectedPromotion = null
    },
    updateVisibleArray() {
      if (window.innerWidth < 768 && this.cardUpdated === false) {
        this.cardUpdated = true
        this.shouldShowLeftArrow = true
        this.shouldShowRightArrow = true
        this.shouldDisableRightArrow = false
        const timeout = sessionStorage.getItem('isFirstMounted') ? 0 : 3000
        clearInterval(this.intervalId);
        this.visibleArray = this.promotions.slice(this.startIndex, this.startIndex + 1)
        const newCard = this.$refs.promotionCard[0];
        newCard.setAttribute('data-active', '')
        setTimeout(() => {
          this.intervalId = setInterval(() => {
            setTimeout(() => {
              if (newCard) {
                newCard.removeAttribute('data-active');
              }
              setTimeout(() => {
                this.startIndex = (this.startIndex + 1) % this.promotions.length
                this.visibleArray = this.promotions.slice(this.startIndex, this.startIndex + 1)
                this.shouldDisableRightArrow = this.visibleArray.at(this.visibleArray.length - 1).data.imageUrl === this.promotions.at(this.promotions.length - 1).data.imageUrl
                newCard.setAttribute('data-active', '');
              }, 200);
            }, 200);
          }, 5000)
        }, timeout)
      } else if (window.innerWidth >= 768 && this.cardUpdated === true) {
        clearInterval(this.intervalId);
        this.cardUpdated = false
        this.shouldShowLeftArrow = false
        this.shouldShowRightArrow = false
        this.startIndex = 0
        this.visibleArray = this.promotions.slice(this.startIndex, this.promotions.length)
      }
    },
    showNextPromotionCards() {
      if(this.disableClick === true) return
      if (window.innerWidth < 768) {
        clearInterval(this.intervalId);
        this.disableClick = true
        this.$refs.nextButton.setAttribute('active', '')
        const newCard = this.$refs.promotionCard[0];
        if (newCard) {
          newCard.removeAttribute('data-active');
        }

        setTimeout(() => {
          if (this.startIndex < this.promotions.length - 1) {
            this.startIndex++;
            this.shouldDisableRightArrow = this.startIndex === 2;
            this.visibleArray = this.promotions.slice(this.startIndex, this.startIndex + 1);
            this.shouldDisableRightArrow = this.visibleArray.at(this.visibleArray.length - 1).data.imageUrl === this.promotions.at(this.promotions.length - 1).data.imageUrl
            newCard.setAttribute('data-active', '');
            this.$refs.nextButton.removeAttribute('active')
            setTimeout(() => {
              this.disableClick = false
            },400)
          }
        }, 400);
      }
    },
    showPrevPromotionCards() {
      if(this.disableClick === true) return
      if (window.innerWidth < 768) {
        clearInterval(this.intervalId);
        this.disableClick = true
        this.$refs.prevButton.setAttribute('active', '')
        const newCard = this.$refs.promotionCard[0];
        if (newCard) {
          newCard.removeAttribute('data-active');
        }

        setTimeout(() => {
          if (this.startIndex !== 0) {
            this.startIndex--
            this.visibleArray = this.promotions.slice(this.startIndex, this.startIndex + 1)
            this.shouldDisableRightArrow = this.visibleArray.at(this.visibleArray.length - 1).data.imageUrl === this.promotions.at(this.promotions.length - 1).data.imageUrl
            newCard.setAttribute('data-active', '');
            this.$refs.prevButton.removeAttribute('active')
            setTimeout(() => {
              this.disableClick = false
            },400)
          }
        }, 400);
      }
    },
    showModal(imageUrl) {
      if (window.innerWidth <= 430  && window.innerHeight >= 600) {
        this.selectedPromotion = imageUrl
      }
    }
  }
}
</script>

<style>
.promotion-container{
  width: 100%;
  height: 100vh;
  height: 100lvh;
  max-height: 1000px;
  min-height: 600px;
  //background-color: yellow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 320px;
}

.promotion-title{
  margin-top: 45px;
  color: white;
  font-size: 40px;
  font-family: "Bebas Neue", sans-serif;
  @media (--large) {
    margin-top: unset;
    font-size: 60px;
  }
}

.card-container{
  height: 70%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: grey;
  @media (--large) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;
    width: 90%;
  }
}

.promotion-card {
  height: 380px;
  background-position: center;
  width: auto;
  opacity: 0;
  transition: 200ms opacity ease-in-out;
  @media (--medium) {
    height: 295px;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 1;
  }
  @media (--large) {
    height: 370px;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 1;
  }
  @media (--page-max-width) {
    height: 480px;
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
    opacity: 1;
  }
}
.promotion-card[data-active] {
  opacity: 1;
}
.promotion-prev-button {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-95-512.png");
  background-size: contain;
  background-repeat: no-repeat;
  transition: scale 300ms ease;
  will-change: transform;
  min-width: 20px;
  &:hover {
    scale: 1.1;
  }
  &:disabled {
    background-image: none;
    cursor: default;
  }
}
.promotion-prev-button[active] {
  animation: clickAnimation 500ms ease;
}
.promotion-next-button {
  margin-left: 10px;
  height: 20px;
  width: 20px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/arrow-30-512.png");
  background-size: contain;
  background-repeat: no-repeat;
  transition: scale 300ms ease;
  will-change: transform;
  min-width: 20px;
  &:hover {
    scale: 1.1;
  }
  &:disabled {
    background-image: none;
    cursor: default;
  }
}
.promotion-next-button[active] {
  animation: clickAnimation 500ms ease;
}
</style>
