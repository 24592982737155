import {productData, productDetail} from "@/components/productList/utils/productObjects";

export const primerSurfacer = [
    productData(
        'Epoxy Primer Green',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/EPOXY%20PRIMER%20GREEN.png'
            ],
        true,
        [
            'anti karat',
            'mudah diaplikasikan',
            'keras dan cepat kering',
            'daya tutup lebih tinggi',
            'tahan terhadap kimia'
        ],
        productDetail(
            'green',
            '1550 gram',
            '75 - 80%',
            '5 - 7 m2 per kg',
            '15 - 20 menit',
            '1,5 kg set',
            'thinner',
            'spray gun, kuas',
            '',
            ''
        ),
        'Epoxy yang berfungsi sebagai lapisan dasar di atas plat yang tahan terhadap karat serta memiliki daya rekat yang sangat baik serta tahan juga terhadap kimia',
        [
            'siapkan Epoxy + Hardener 6 : 1',
            'tambahkan thinner dan aduk rata',
            'aplikasikan di bidang yang bersih',
            'spray...(cepat merata)',
            'kering sentuh 20 menit...(aman dipegang)',
            'setelah 4 jam...siap amplas + naik cat',

        ],
        [],
        '',
    ),
    productData(
        'Epoxy Primer Surfacer',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/EPOXY%20PRIMER%20SURFACER.png'
            ],
        null,
        [
            'mudah diaplikasikan',
            'keras dan cepat kering',
            'daya tutup lebih tinggi',
            'tahan terhadap kimia',
        ],
        productDetail(
            'grey',
            '1550 gram',
            '75 - 80%',
            '5 - 7 m2 per kg',
            '15 - 20 menit',
            '1,5 kg set',
            'thinner',
            'spray gun, kuas',
            '',
            ''
        ),
        'Epoxy yang berfungsi sebagai lapisan dasar di atas plat yang memiliki daya rekat sangat baik serta tahan terhadap kimia',
        [
            'siapkan Epoxy + Hardener 6 : 1',
            'tambahkan thinner dan aduk rata',
            'aplikasikan di bidang yang bersih',
            'spray...(cepat merata)',
            'kering sentuh 20 menit...(aman dipegang)',
            'setelah 4 jam...siap amplas + naik cat'
        ],
        [],
        ''
    ),
    productData(
        'Epoxy Filler',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/EPOXY%20FILLER.png',
            ],
        true,
        [
            'mudah diaplikasikan',
            'cepat kering',
            'lebih halus',
        ],
        productDetail(
            'grey, white, black',
            '1280 gram',
            '75 - 80%',
            '5 - 7 m2 per kg',
            '15 - 20 menit',
            '1,2 kg set',
            'thinner',
            'spray gun, kuas',
            '',
            ''

        ),
        'Epoxy yang berfungsi sebagai lapisan di atas dempul yang memiliki daya rekat yang sangat baik serta hasil yang lebih halus',
        [
            'siapkan Epoxy + Hardener 6 : 1',
            'tambahkan thinner dan aduk rata',
            'aplikasikan di bidang yang bersih',
            'spray...(cepat merata)',
            'kering sentuh 20 menit...(aman dipegang)',
            'setelah 4 jam...siap amplas + naik cat'
        ],
        ['yellow', 'red', 'purple', 'rebeccapurple rebeccapurple rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple', 'yellow', 'red', 'purple', 'rebeccapurple'],
        // ['yellow'],
        '',
        'https://wa.me/6285159122501?text=Chat%20with%20our%20admin',
        'https://wa.me/6285159122501?text=Chat%20with%20our%20admin'
    ),
    productData(
        'Surfacer PU 2K',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/SURFACER%20PU%202K.png',
        ],
        true,
        [
            'mudah diaplikasikan',
            'daya rekat bagus',
            'cepat kering',
            'daya tutup tinggi',
            'tahan terhadap kimia',
            'waktu amplas singkat, hanya 40 menit'
        ],
        productDetail(
            'grey, black, white',
            '1395 gram',
            '75 - 80%',
            '',
            '30 - 40 menit',
            '1,2 kg set',
            'thinner',
            'spray gun, kuas',
            '',
            ''

        ),
        'Epoxy Poly Urethane 2 Komponen yang berfungsi sebagai lapisan dasar yang memiliki daya rekat yang sangat baik serta tahan juga terhadap kimia',
        [
            'siapkan Surfacer + Hardener 6 : 1',
            'tambahkan thinner dan aduk rata',
            'aplikasikan di bidang yang bersih',
            'setelah kering sekitar 40 menit...siap amplas',
        ],
        [],
        'Epoxy Poly Urethane 2 Komponen yang berfungsi sebagai lapisan dasar yang memiliki daya rekat yang sangat baik serta tahan juga terhadap kimia',
    )
]

export const clearCoatReducer = [
    productData(
        'Clear S2',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/S2.png'
            ],
        true,
        [
            'Tingkat kilap yang tinggi dan ketahanan kilap yang lama',
            'Tingkat kekerasan yang tinggi',
            'Cepat kering dan tahan gores'
        ],
        productDetail(
            'bening',
            '1665 gram',
            '75 - 80%',
            '7 - 9 m2 per set kemasan',
            '10 - 20 menit',
            '1,5 Lt/set',
            'thinner',
            'spray gun',
            'glossy',
            ''
        ),
        'Merupakan produk High Solid Clear Coat 2 Komponen yang sangat glossy, keras dan tahan lama. Produk ini diaplikasikan dengan metode spray dan memiliki hasil kilap yang amat sangat baik, tahan terhadap UV, terhadap cuaca dan juga tahan terhadap bensin',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 2 : 1 : 75%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel',

        ],
        [],
        'High Solid Clear Coat 2 Komponen yang sangat glossy, tahan lama, dan keras. Aplikasikan dengan metode spray, memiliki hasil kilap luar biasa, tahan UV, cuaca, dan bensin'
    ),
    productData(
        'Clear S1',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/S1.png'
            ],
        null,
        [
            'Tingkat kilap yang baik dan tahan lama',
            'Tingkat kekerasan yang baik',
            'Cepat kering dan tahan gores'
        ],
        productDetail(
            'bening',
            '1550 gram',
            '75 - 80%',
            '7 - 9 m2 per set kemasan',
            '10 - 20 menit',
            '1,5 Lt/set',
            'thinner',
            'spray gun',
            'glossy',
            ''
        ),
        'Merupakan produk Medium Solid Clear Coat 2 Komponen yang glossy, keras dan tahan lama. Produk ini diaplikasikan dengan metode spray dan memiliki hasil kilap yang baik, tahan terhadap UV, terhadap cuaca dan juga tahan terhadap bensin',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 4 : 1 : 75%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel'
        ],
        [],
        'Medium Solid Clear Coat 2 Komponen yang glossy, keras, dan tahan lama. Aplikasikan dengan metode spray, memiliki hasil kilap yang baik, tahan terhadap UV, cuaca, dan bensin',
    ),
    productData(
        'Clear Premium',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PREMIUM.png'
        ],
        null,
        [
            'Tingkat kilap yang baik dan tahan lama',
            'Tingkat kekerasan yang baik',
            'Cepat kering dan tahan gores'
        ],
        productDetail(
            'bening',
            '1590 gram',
            '75 - 80%',
            '7 - 9 m2 per set kemasan',
            '10 - 20 menit',
            '1,5 Lt/set',
            'thinner',
            'spray gun',
            'glossy',
            ''
        ),
        'Merupakan produk High Solid Clear Coat 2 Komponen yang sangat glossy, keras dan tahan lama. Produk ini diaplikasikan dengan metode spray dan memiliki hasil kilap yang baik, tahan terhadap UV, terhadap cuaca dan juga tahan terhadap bensin',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 2 : 1 : 75%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel'
        ],
        [],
        'High Solid Clear Coat 2 Komponen yang sangat glossy, keras dan tahan lama. Aplikasikan dengan metode spray, memiliki hasil kilap yang baik, tahan terhadap UV, cuaca dan bensin'
    ),
    productData(
        'Clear 901',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/901.png'
            ],
        null,
        [
            'Tingkat kilap yang baik dan tahan lama',
            'Tingkat kekerasan yang baik',
            'Cepat kering dan tahan gores'
        ],
        productDetail(
            'bening',
            '1245 gram',
            '75 - 80%',
            '6 - 8 m2 per set kemasan',
            '10 - 20 menit',
            '1,25 Lt/set',
            'thinner',
            'spray gun',
            'glossy',
            ''
        ),
        'Merupakan produk Medium Solid Clear Coat 2 Komponen yang glossy, keras dan tahan lama. Produk ini diaplikasikan dengan metode spray dan memiliki hasil kilap yang baik, tahan terhadap UV, terhadap cuaca dan juga tahan terhadap bensin',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 4 : 1 : 75%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel'
        ],
        [],
        'Medium Solid Clear Coat 2 Komponen yang glossy, keras dan tahan lama. Aplikasikan dengan metode spray, memiliki hasil kilap yang baik, tahan terhadap UV, cuaca dan bensin.'
    ),
    productData(
        'Clear 705',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/705.png'
        ],
        true,
        [
            'Tingkat kilap yang baik dan tahan lama',
            'Tingkat kekerasan yang baik',
            'Cepat kering dan tahan gores'
        ],
        productDetail(
            'bening',
            '1245 gram',
            '75 - 80%',
            '6 - 8 m2 per set kemasan',
            '10 - 20 menit',
            '1,25 Lt/set',
            'thinner',
            'spray gun',
            'glossy',
            ''
        ),
        'merupakan produk Medium Solid Clear Coat 2 Komponen yang glossy, keras dan tahan lama. Produk ini diaplikasikan dengan metode spray dan memiliki hasil kilap yang baik, tahan terhadap UV, terhadap cuaca dan juga tahan terhadap bensin serta lebih cepat kering',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 4 : 1 : 75%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel'
        ],
        [],
        'Medium Solid Clear Coat 2 Komponen yang glossy, keras, tahan lama dan cepat kering. Aplikasikan dengan metode spray, memiliki hasil kilap yang baik, tahan terhadap UV, cuaca dan bensin'
    ),
    productData(
        'Geo Clear',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/GEO.png'
        ],
        null,
        [
            'Tingkat kilap yang baik dan tahan lama',
            'Tingkat kekerasan yang baik',
            'Cepat kering dan tahan gores'
        ],
        productDetail(
            'bening',
            '600 gram',
            '75 - 80%',
            '4 - 6 m2 per set kemasan',
            '10 - 20 menit',
            '0,5 Lt/set',
            'thinner',
            'spray gun',
            'glossy',
            ''
        ),
        'Merupakan produk High Solid Clear Coat 2 Komponen yang sangat glossy, keras dan tahan lama. Produk ini diaplikasikan dengan metode spray dan memiliki hasil kilap yang baik, tahan terhadap UV, terhadap cuaca dan juga tahan terhadap bensin',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 2 : 1 : 75%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel'
        ],
        [],
        'High Solid Clear Coat 2 Komponen yang sangat glossy, keras dan tahan lama. Aplikasikan dengan metode spray, memiliki hasil kilap yang baik, tahan terhadap UV, cuaca dan bensin'
    ),
    productData(
        'Clear Doff',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/20240627_144858-removebg-preview.png'
            ],
        null,
        [
            'Tahan lama',
            'Tingkat kekerasan yang baik',
            'Cepat kering dan tahan gores'
        ],
        productDetail(
            'bening',
            '1215 gram',
            '75 - 80%',
            '6 - 8 m2 per set kemasan',
            '10 - 20 menit',
            '1,25 Lt/set',
            'thinner',
            'spray gun',
            'semi gloss',
            ''
        ),
        'Merupakan produk Medium Solid Clear Coat 2 Komponen yang keras dan tahan lama. Produk ini diaplikasikan dengan metode spray dan memiliki kekerasan yang baik, tahan terhadap UV, terhadap cuaca dan juga tahan terhadap bensin',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 4 : 1 : 75%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel'
        ],
        [],
        'Medium Solid Clear Coat 2 Komponen yang keras dan tahan lama. Aplikasikan dengan metode spray, memiliki kekerasan yang baik, tahan terhadap UV, cuaca dan bensin'
    ),
    productData(
        '109s',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/THINNER%20109.png'
        ],
        true,
        [
            'daya larut sangat bagus',
            'menambah daya kilap yang tinggi pada clear coat',
            'hasil pengecatan lebih merata'
        ],
        productDetail(
            'bening',
            '860 gram',
            '75 - 80%',
            '',
            '40 - 50 menit',
            '950 ml',
            '',
            '',
            '',
            ''
        ),
        'pelarut yang digunakan untuk mengencerkan dan melarutkan cat jenis Polyurethane-Acrylic (PU). Thinner ini memiliki kemampuan tinggi untuk melarutkan cat PU dan memberikan kilap pada permukaan yang dicat',
        [
            'siapkan thinnernya',
            'buka dan tuang',
            'atur komposisi yang sesuai',
            'aduk sampai rata'
        ],
        [],
        'Pelarut untuk mengencerkan dan melarutkan cat Polyurethane-Acrylic (PU), dengan kemampuan tinggi melarutkan cat PU dan memberikan kilap pada permukaan'
    ),
    productData(
        'Xtra Slow',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/THINNER%20XTRA%20SLOW.png'
        ],
        null,
        [
            'daya larut sangat bagus',
            'menambah daya kilap yang tinggi pada clear coat',
            'hasil pengecatan lebih merata',
            'mudah diaplikasikan'
        ],
        productDetail(
            'bening',
            '860 gram',
            '75 - 80%',
            '',
            '60 - 70 menit',
            '1 ltr',
            '',
            '',
            '',
            ''
        ),
        'pelarut yang digunakan untuk mengencerkan dan melarutkan cat. Thinner ini memiliki kemampuan tinggi untuk melarutkan cat dan memberikan kilap pada permukaan yang dicat',
        [
            'siapkan thinnernya',
            'buka dan tuang',
            'atur komposisi yang sesuai',
            'aduk sampai rata'
        ],
        [],
        'Pelarut untuk mengencerkan dan melarutkan cat, dengan kemampuan tinggi melarutkan cat dan memberikan kilap pada permukaan'
    ),
    productData(
        'Thinner HG+',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/HG%2B.png'
        ],
        null,
        [
            'Lebih mengkilap',
            'Lebih halus'
        ],
        productDetail(
            'Tidak berwarna/bening',
            '3990 gram',
            '',
            '',
            '',
            '4,5  ltr',
            '',
            '',
            '',
            ' 0%'
        ),
        'Untuk mengencerkan cat besi, cat kayu, politur dan bahan finishing termasuk jenis thinner NC yang dirancang untuk kilap tinggi.',
        [
            'tambahkan thinner',
            'aduk sampai rata'
        ],
        [],
        'Untuk mengencerkan cat besi, cat kayu, politur dan bahan finishing termasuk jenis thinner NC yang dirancang untuk kilap tinggi.'
    ),
    productData(
        'Thinner Acrylic+',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/AC%2B.png'
        ],
        null,
        [
            'lebih cepat kering',
            'lebih mengkilap'
        ],
        productDetail(
            'tidak berwarna/bening',
            '4435 gram',
            '',
            '',
            '',
            '4,5 ltr',
            '',
            '',
            '',
            '0%'
        ),
        'Untuk mengencerkan cat berbahan dasar enamel akrilik, menjadikan lebih mengkilap dan lebih cepat kering.',
        [
            'tambahkan thinner',
            'aduk sampai rata'
        ],
        [],
        'Untuk mengencerkan cat berbahan dasar enamel akrilik, menjadikan lebih mengkilap dan lebih cepat kering.'
    )
]

export const zincChromate = [
    productData(
        'Zinc Chromate',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/ZINC%20CHROMATE.png'
            ],
        true,
        [
            'hasil lebih halus',
            'daya rekat kuat',
            'cepat kering',
            'anti korosi',
            'aplikasi cat, tidak perlu menunggu kering',
            'lebih hemat, bisa menggunakan bensin sebagai pengencer'
        ],
        productDetail(
            'grey, dark green, black',
            '1130 gram',
            '75 - 80%',
            '5 - 7 m2 per kg',
            '15 - 20 menit',
            '1 kg',
            'bensin',
            'spray gun, kuas',
            '',
            ''
        ),
        'Cat dasar anti karat dengan hasil yang lebih halus, berdaya rekat kuat dan cepat kering',
        [
            'siapkan Zinc Chromate',
            'tambahkan bensin dan aduk rata',
            'aplikasikan di bidang yang bersih',
            'spray/kuas secara merata',
            'biarkan kering dengan cepat dan siap diamplas jika diperlukan'
        ],
        [],
    )
]

export const ppPrimerSurfacer = [
    productData(
        'PP Primer Surfacer - Light Grey',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PP%20LIGHT%20GREY.png'
        ],
        null,
        [
            'praktis',
            'langsung diaplikasikan',
            'sangat cocok untuk spot repair, di atas plastik atau dempul',
            'tidak mudah retak dan terkelupas',
        ],
        productDetail(
            'light grey',
            '250 gram',
            '45 - 50%',
            '2 - 3 m2 per 250 gram',
            '5 - 10 menit',
            '250 ml, 500 ml, 1000 ml',
            'tidak pakai',
            'spray gun',
            'dof',
            ''
        ),
        'Primer berkualitas untuk plastik. Direkomendasikan untuk aplikasi di plastik pada body sepeda motor dan sebagian body mobil untuk mendapatkan adhesi yang sempurna pada lapisan cat diatasnya. Bisa diaplikasikan di atas dempul',
        [
            'kocok dahulu selama minimal 15 detik',
            'tuang ke spray gun anda',
            'dan siap langsung di-spray',
            'diamkan sebentar...(cepat kering)',
            'siap naik cat'
        ],
        [],
        'Primer berkualitas untuk plastik agar mendapatkan adhesi yang sempurna pada lapisan cat diatasnya. Bisa diaplikasikan di atas dempul'
    ),
    productData(
        'PP Primer Surfacer - Black Matte',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PP%20BLACK%20MATTE.png'
            ],
        true,
        [
            'praktis',
            'langsung diaplikasikan',
            'sangat cocok untuk menjadikan baru kembali body kasar/dashboard',
            'tidak mudah retak dan terkelupas',
        ],
        productDetail(
            'black matte',
            '250 gram',
            '45 - 50%',
            '2 - 3 m2 per 250 gram',
            '5 - 10 menit',
            '250 ml, 500 ml, 1000 ml',
            'tidak pakai',
            'spray gun',
            'matte',
            ''
        ),
        'Primer berkualitas untuk plastik. Direkomendasikan untuk aplikasi di plastik pada body sepeda motor dan sebagian body mobil untuk mendapatkan adhesi yang sempurna pada lapisan cat diatasnya. Bisa diaplikasikan di atas dempul',
        [
            'kocok dahulu selama minimal 15 detik',
            'tuang ke spray gun anda',
            'dan siap langsung di-spray',
            'diamkan sebentar...(cepat kering)',
        ],
        [],
        'Primer berkualitas untuk plastik agar mendapatkan adhesi yang sempurna pada lapisan cat diatasnya. Bisa diaplikasikan di atas dempul'
    ),
    productData(
        'PP Primer Surfacer - Silver',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/PP%20SILVER%20HALUS.png'
            ],
        null,
        [
            'praktis',
            'langsung diaplikasikan',
            'sangat cocok untuk dasaran cat candy',
            'tidak mudah retak dan terkelupas',
        ],
        productDetail(
            'silver',
            '250 gram',
            '45 - 50%',
            '2 - 3 m2 per 250 gram',
            '5 - 10 menit',
            '250 ml, 500 ml, 1000 ml',
            'tidak pakai',
            'spray gun',
            'flat',
            ''
        ),
        'Primer berkualitas untuk plastik. Direkomendasikan untuk aplikasi di plastik pada body sepeda motor dan sebagian body mobil untuk mendapatkan adhesi yang sempurna pada lapisan cat diatasnya. Bisa diaplikasikan di atas dempul',
        [
            'kocok dahulu selama minimal 15 detik',
            'tuang ke spray gun anda',
            'dan siap langsung di-spray',
            'diamkan sebentar...(cepat kering)',
            'siap naik cat'
        ],
        [],
        'Primer berkualitas untuk plastik agar mendapatkan adhesi yang sempurna pada lapisan cat diatasnya. Bisa diaplikasikan di atas dempul'
    )
]

export const catDumons = [
    productData(
        'Cat NC Solid',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20NC%20SOLID.png',
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20NC%20SOLID.png'
        ],
        true,
        [
            'cepat kering',
            'daya tutup baik',
            'tahan lama',
            'daya rekat baik',
        ],
        productDetail(
            'solid',
            '1160 gram',
            '75 - 80%',
            '',
            '30 - 40 menit',
            '1  ltr',
            '',
            '',
            '',
            ''
        ),
        'Cat terbuat dari resin Nitrocellulose yang cepat kering berdaya tutup baik, dan performa warna yang tahan lama, serta memiliki adhesi yang baik.',
        [
            'aduk sebelum di tambahkan thinner',
            'tambahkan thinner',
            'aduk sampai rata',
            'semprotkan secara merata',
        ],
        [],
        'Cat berbahan resin Nitrocellulose yang cepat kering berdaya tutup baik, dan performa warna yang tahan lama, serta memiliki adhesi yang baik.',
    ),
    productData(
        'Cat NC Metallic',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20NC%20METALLIC.png'
        ],
        true,
        [
            'cepat kering',
            'daya tutup baik',
            'tahan lama',
            'daya rekat baik',
        ],
        productDetail(
            'metallic',
            '1160 gram',
            '75 - 80%',
            '',
            '60 - 70 menit',
            '1  ltr',
            '',
            '',
            '',
            ''
        ),
        'Cat terbuat dari resin Nitrocellulose yang cepat kering berdaya tutup baik, performa warna yang tahan lama dan memiliki adhesi yang baik. Serta mengandung pigment metallic / pearl sehingga memberikan warna yang indah dan menarik',
        [
            'aduk sebelum di tambahkan thinner',
            'tambahkan thinner',
            'aduk sampai rata',
            'semprotkan secara merata',
        ],
        [],
        'Cat berbahan resin Nitrocellulose, cepat kering, daya tutup baik, warna tahan lama, dan adhesi kuat. Mengandung pigmen metallic/pearl untuk tampilan warna indah dan menarik.'
    ),
    productData(
        'Cat NC Motor',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20NC%20MOTOR.png'
        ],
        null,
        [
            'cepat kering',
            'daya tutup baik',
            'tahan lama',
            'daya rekat baik',
        ],
        productDetail(
            'mixed color',
            '1160 gram',
            '75 - 80%',
            '',
            '60 - 70 menit',
            '1  ltr',
            '',
            '',
            '',
            ''
        ),
        'Cat terbuat dari resin Nitrocellulose yang cepat kering berdaya tutup baik, performa warna yang tahan lama serta memiliki adhesi yang baik. Dengan berbagai warna-warna motor yang menarik',
        [
            'aduk sebelum di tambahkan thinner',
            'tambahkan thinner',
            'aduk sampai rata',
            'semprotkan secara merata',
        ],
        [],
        'Cat berbahan resin Nitrocellulose, cepat kering, berdaya tutup baik, performa warna yang tahan lama dan adhesi yang baik. Dengan berbagai warna-warna motor yang menarik'
    ),
    productData(
        'Cat Tinting PU',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20TINTING%20PU.png'
        ],
        true,
        [
            'daya tutup sangat baik',
            'tahan lama',
            'daya rekat sangat baik',
        ],
        productDetail(
            'multi color',
            '1050 gram',
            '75 - 80%',
            '',
            '60 - 70 menit',
            '1  ltr',
            '',
            '',
            '',
            ''
        ),
        'Cat terbuat dari resin Poly Urethane yang berdaya tutup sangat baik, warna yang tahan lama dan memiliki adhesi yang sangat baik. Serta memiliki aneka warna yang indah dan menarik yang anda butuhkan',
        [
            'aduk sebelum di tambahkan thinner',
            'tambahkan thinner',
            'aduk sampai rata',
            'semprotkan secara merata',
        ],
        [],
        'Cat berbahan resin Poly Urethane, berdaya tutup sangat baik, warna yang tahan lama dan adhesi yang sangat baik. Memiliki aneka warna yang indah dan menarik yang anda butuhkan'
    ),
    productData(
        'Cat Ready Mix Ultimate Mobil',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20ULTIMATE%20PU%20MOBIL.png'
        ],
        null,
        [
            'daya tutup sangat baik',
            'tahan lama',
            'daya rekat sangat baik',
        ],
        productDetail(
            'multi color',
            '1050 gram',
            '75 - 80%',
            '',
            '60 - 70 menit',
            '1  ltr',
            '',
            '',
            '',
            ''
        ),
        'Cat terbuat dari resin Poly Urethane berdaya tutup sangat baik, warna tahan lama dan memiliki adhesi yang sangat baik. Dengan aneka warna- warna mobil yang menarik',
        [
            'aduk sebelum di tambahkan thinner',
            'tambahkan thinner',
            'aduk sampai rata',
            'semprotkan secara merata',
        ],
        [],
        'Cat berbahan resin Poly Urethane, daya tutup sangat baik, warna tahan lama dan adhesi yang sangat baik. Dengan aneka warna- warna mobil yang menarik'
    ),
    productData(
        'Cat Ready Mix Ultimate Motor',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/3_20241216_095748_0002.png'
        ],
        null,
        [
            'daya tutup sangat baik',
            'tahan lama',
            'daya rekat sangat baik',
        ],
        productDetail(
            'multi color',
            '235 gram',
            '75 - 80%',
            '',
            '5 menit',
            '250  ml',
            '',
            '',
            '',
            ''
        ),
        'Cat Poly Urethane 1 Komponen yang berdaya tutup sangat baik, warna tahan lama dan memiliki adhesi yang sangat baik. Dengan aneka warna- warna motor yang menarik. Praktis, tinggal tuang dan spray.',
        [
            'kocok dahulu beberapa saat',
            'tidak perlu ditambahkan thinner',
            'tuang ke spray gun',
            'semprotkan secara merata',
        ],
        [],
        'Cat Poly Urethane 1 Komponen berdaya tutup sangat baik, tahan lama dan memiliki adhesi yang sangat baik. Dengan aneka warna- warna motor yang menarik. Praktis, tinggal tuang dan spray.',
    ),
    productData(
        'Cat Candy PU',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20CANDY%20PU.png'
        ],
        null,
        [
            'daya tutup sangat baik',
            'tahan lama',
            'daya rekat sangat baik',
        ],
        productDetail(
            'multi color',
            '1050 gram',
            '75 - 80%',
            '',
            '60 - 70 menit',
            '1  ltr',
            '',
            '',
            '',
            ''
        ),
        'Cat terbuat dari resin Poly Urethane berdaya tutup sangat baik, warna tahan lama dan memiliki adhesi yang sangat baik. Dengan aneka warna- warna candy yang menarik',
        [
            'aduk sebelum di tambahkan thinner',
            'tambahkan thinner',
            'aduk sampai rata',
            'semprotkan secara merata',
        ],
        [],
        'Cat berbahan resin Poly Urethane, daya tutup sangat baik, warna tahan lama dan adhesi yang sangat baik. Dengan aneka warna- warna candy yang menarik'
    ),
    productData(
        'Cat Flourescent PU',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/CAT%20FLUORESCENT%20PU.png'
        ],
        null,
        [
            'daya tutup sangat baik',
            'tahan lama',
            'daya rekat sangat baik',
        ],
        productDetail(
            'multi color',
            '1050 gram',
            '75 - 80%',
            '',
            '60 - 70 menit',
            '1  ltr',
            '',
            '',
            '',
            ''
        ),
        'Cat terbuat dari resin Poly Urethane berdaya tutup sangat baik, warna tahan lama dan memiliki adhesi yang sangat baik. Serta mengandung pigment flourescent yang memberikan warna yang indah dan menarik',
        [
            'aduk sebelum di tambahkan thinner',
            'tambahkan thinner',
            'aduk sampai rata',
            'semprotkan secara merata',
        ],
        [],
        'Cat berbahan resin Poly Urethane, daya tutup sangat baik, warna tahan lama dan adhesi yang sangat baik. Mengandung pigment flourescent, memberikan warna indah dan menarik'
    )
]

export const woodCoating = [
    productData(
        'Sanding Sealer',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/MELAMINE%20SANDING%20SEALER.png'
        ],
        null,
        [
            'tahan terhadap goresan',
            'tahan terhadap cuaca',
            'mempertahankan keindahan serat kayu',
            'menyamarkan cacat permukaan, noda ringan dan bercak'
        ],
        productDetail(
            'white',
            '1145 gram',
            '75 - 80%',
            '6 - 7 m2 per kg',
            '15 - 20 menit',
            '1 kg set',
            'thinner',
            'spray gun, kuas',
            '',
            ''
        ),
        'lapisan dasar yang menghasilkan warna natural berguna untuk menutup pori-pori kayu sebelum dicat dan mencegah masuknya cairan melalui permukaan atau sambungan',
        [
            'siapkan Sanding Sealer + Hardener 6 : 1',
            'tambahkan thinner dan aduk rata',
            'aplikasikan di bidang yang bersih',
            'spray...(cepat merata)',
            'kering sentuh 20 menit...(aman dipegang)'
        ],
        [],
        'Lapisan dasar yang menghasilkan warna natural, menutup pori-pori kayu sebelum pengecatan dan mencegah masuknya cairan melalui permukaan atau sambungan'
    ),
    productData(
        'Wood Stain',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WOOD%20STAIN.png'
        ],
        null,
        [
            'daya rekat kuat',
            'daya tutup baik',
            'tahan terhadap cuaca',
        ],
        productDetail(
            'salak brown, walnut brown, cocoa brown',
            '930 gram',
            '75 - 80%',
            '5 - 6 m2 per ltr',
            '15 - 20 menit',
            '1 ltr',
            'thinner',
            'spray gun, kuas',
            '',
            ''
        ),
        'cat pelapis kayu yang didesain agar kayu tahan lebih lama, memberi perlindungan dan tampilan warna, mudah untuk digunakan serta mudah dibersihkan',
        [
            'bersihkan bidang yang akan diaplikasikan',
            'spray/kuas secara merata',
            'keringkan',
        ],
        [],
        'Cat pelapis kayu yang didesain agar kayu tahan lebih lama, memberi perlindungan dan tampilan warna, mudah untuk digunakan serta mudah dibersihkan'
    ),
    productData(
        'Wood Filler',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/WOOD%20FILLER.png'
        ],
        true,
        [
            'mudah diaplikasikan',
            'cepat kering',
            'lebih halus',
            'daya sebar baik'
        ],
        productDetail(
            'white',
            '1105 gram',
            '75 - 80%',
            '6 - 7 m2 per kg',
            '15 - 20 menit',
            '1 kg',
            'thinner',
            'spray gun, kuas',
            '',
            ''
        ),
        'cat dasar yang berfungsi untuk mengisi lobang/pori dan menghaluskan permukaan kayu, sebelum pengecatan akhir',
        [
            'bersihkan bidang yang akan diaplikasikan',
            'spray/kuas secara merata',
            'keringkan',
        ],
        [],
        'Cat dasar yang berfungsi untuk mengisi lobang/pori dan menghaluskan permukaan kayu, sebelum pengecatan akhir'
    ),
    productData(
        'Melamine Clear Gloss',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/6_20241128_112627_0005.png'
        ],
        true,
        [
            'Lambat menguning ',
            'Tingkat kekerasan baik',
            'Tahan bahan kimia',
            'Tahan gores'
        ],
        productDetail(
            'bening',
            '1140 gram',
            '75 - 80%',
            '6 - 7 m2 per set kemasan',
            '',
            '1 kg/set',
            'thinner',
            'spray gun',
            'glossy',
            ''
        ),
        'produk finishing kayu 2 komponen yang lambat menguning, cukup tebal, halus dan rata sangat tepat memberikan kesan lebih mewah dan elegan dengan memunculkan keindahan warna kayu',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 4 : 1 : 50%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel',
        ],
        [],
        'Finishing kayu 2 komponen, lambat menguning, tebal, halus, dan rata, memberikan kesan mewah serta mempertegas keindahan warna kayu',
    ),
    productData(
        'Melamine Clear Doff',
        [
            'https://raw.githubusercontent.com/hanifsyahsn/dumons-asset/refs/heads/main/images/7_20241128_112627_0006.png'
        ],
        true,
        [
            'Lambat menguning ',
            'Tingkat kekerasan baik',
            'Tahan bahan kimia',
            'Tahan gores'
        ],
        productDetail(
            'bening',
            '1145 gram',
            '75 - 80%',
            '6 - 7 m2 per set kemasan',
            '',
            '1 kg/set',
            'thinner',
            'spray gun',
            'doff, matte',
            ''
        ),
        'produk finishing kayu 2 komponen yang lambat menguning, cukup tebal, halus dan rata sangat tepat memberikan kesan lebih mewah dan elegan dengan memunculkan keindahan warna kayu',
        [
            'Bersihkan permukaan dari kotoran dan debu yang menempel',
            'Campurkan Clear dan Hardener terlebih dahulu sebelum menambah thinner dengan komposisi 4 : 1 : 50%',
            'Aduk hingga tercampur rata dan siap untuk dispray pada panel',
        ],
        [],
        'Finishing kayu 2 komponen, lambat menguning, tebal, halus, dan rata, memberikan kesan mewah serta mempertegas keindahan warna kayu.',
    )
]
